<template>
	<!-- <el-dialog :title="$t('7fb6ada66a1fccca')" append-to-body :close-on-click-modal="false" :visible.sync="dialog" width="1000px"> -->
	<el-drawer :wrapperClosable="false" title="" append-to-body :visible.sync="dialog" size="1000">
		<template slot="title">
			<h3><span>{{$t('7fb6ada66a1fccca')}}</span>{{$t('i18nn_c38333badc226309')}}</h3>
		</template>
		<div>
			<!-- <WSkuInventories :isSel="true"></WSkuInventories> -->
			<!-- <div class="tableConTop">
				<el-row>
					<el-col :span="5" class="tableConTopLeft">
						<h3>
							<span>{{ $t('Storage.nav.SKU_inventories') }}</span>
						</h3>
					</el-col>
					<el-col :span="19" class="tableConTopRig">
						<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
						</el-button>
					</el-col>
				</el-row>
			</div> -->
			<!-- <div class="formMsgCon">
			    <h3><i class="titleIcon el-icon-bell"></i>{{$t('i18nn_41413cddfa61538c')}}</h3>
			    <div class="formMsgDet">
			      <p>
			        此为录入仓库的SKU数据
			      </p>
			    </div>
			</div> -->
			<div class="filterCon" style="">
				<div class="filterBarList">

				</div>
				<div class="filterAction">
					<ul class="actionConList">

					</ul>
					<ul class="filterConList">
						<!-- <li>
							<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
							<SelAgentUser @change="changeAgentUser"></SelAgentUser>
						</li> -->
						<!-- <li>
							<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
							<whNoSelect @changeData="changWhNo"></whNoSelect>
						</li> -->
						<li>
							<span>{{$t('i18nn_43753ad5329348d6')}}</span>
							<el-input :placeholder="$t('i18nn_f353b1ebbdc25be4')" size="small"
								v-model="filterData.keyword" maxlength="50" :clearable="true" style="width: 150px;"
								@keyup.enter.native="initData"></el-input>
						</li>
						<li>
							<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">
							</el-button>
						</li>
						<!-- <li>
							<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()"></el-button>
						</li> -->
					</ul>
				</div>
			</div>
			<!-- <div class="wh-filter-con">
				<div class="wh-filter-item">
					<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
					<SelAgentUser @change="changeAgentUser"></SelAgentUser>
				</div>
				<div class="wh-filter-item">
					<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
					<whNoSelect @changeData="changWhNo"></whNoSelect>
				</div>
				<div class="wh-filter-item">
					<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
					<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword" maxlength="50" :clearable="true" style="width: 150px;"></el-input>
				</div>
				<el-button type="primary" @click="serPageData" size="small">{{ $t('FormMsg.Search') }}</el-button>
			</div> -->

			<!-- <div class="wh-action-con">
			    <el-button type="success" @click="openDioalog" size="small" icon="el-icon-plus">{{$t('Storage.skuInfo.Add_SKU')}}</el-button>
			    <el-button type="warning" size="small" icon="el-icon-upload">{{$t('Storage.skuInfo.Import_SKU')}}</el-button>
			</div> -->

			<div class=""  v-loading="1==pagination.current_page?loading_load:false" :element-loading-text="$t('tips.loading')">
				<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
					:height="$store.state.tableMaxHeight4" @selection-change="handleSelectionChange"
					@row-click="handleCurrentChange" v-el-table-infinite-scroll= "load"
					:infinite-scroll-disabled="infinite_loading_disabled" :infinite-scroll-immediate="false" :infinite-scroll-delay="200">
					<!-- <el-table-column type="index" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column> -->
					<el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra">
					</el-table-column>
					<el-table-column type="index" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>
					
					<el-table-column prop="state" :label="$t('6cdece641436d7ab')">
						<template slot-scope="scope">
							<el-tag v-if="'1'===scope.row.state" type="success">{{$t('i18nn_889b3ef0590d9f54')}}</el-tag>
							<el-tag v-else-if="'0'===scope.row.state" type="warning">{{$t('i18nn_11419332713360d2')}}</el-tag>
							<el-tag v-else type="info">{{scope.row.state}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="account" label="账号(邮箱)">
					</el-table-column>
					<el-table-column prop="mobile" :label="$t('e82c9fff83ec2d91')">
					</el-table-column>
					<el-table-column prop="name" :label="$t('i18nn_24b9c5e694c18344')">
					</el-table-column>
					<!-- <el-table-column prop="avatar" :label="$t('bdaf0b4164ce179e')">
						<template slot-scope="scope">
							<div>
								<el-image lazy style="width: 40px; height: 40px" fit="contain" :src="scope.row.avatar">
									<div slot="error" class="image-slot" style="font-size: 40px;"><i
											class="el-icon-picture-outline"></i></div>
								</el-image>
							</div>
						</template>
					</el-table-column> -->
					<!-- <el-table-column prop="isSubUser" :label="$t('i18nn_4a0cacddeb2e38b1')">
					</el-table-column>
					<el-table-column prop="parentMobile" :label="$t('i18nn_bf55171bd3928132')">
					</el-table-column> -->
					<!-- <el-table-column prop="enterpriseState" :label="$t('i18nn_7f28764b786320f9')">
					</el-table-column>
					<el-table-column prop="shopState" :label="$t('i18nn_b11cc38d93b92f1c')">
					</el-table-column> -->
					<!-- <el-table-column prop="isParnter" :label="$t('i18nn_09737906ddb3e95e')">
					</el-table-column>
					<el-table-column prop="partnerPhone" :label="$t('i18nn_dcc52d1264a9722b')">
					</el-table-column>
					<el-table-column prop="linkman" :label="$t('i18nn_16e71584163b9bf7')">
					</el-table-column> -->
					<!-- <el-table-column prop="businessDivisionName" :label="$t('i18nn_daa52e38a6df288d')">
					</el-table-column> -->
					<el-table-column prop="partnerPhone" :label="$t('i18nn_3f621a26b8218f81')">
						<template slot-scope="scope">
							<div>
								<div>{{scope.row.partnerPhone}}</div>
								<div>{{scope.row.linkman}}</div>
							</div>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="linkman" :label="$t('i18nn_16e71584163b9bf7')">
					</el-table-column> -->
					<el-table-column prop="id" label="ID">
					</el-table-column>
					<el-table-column prop="registerTime" :label="$t('i18nn_8845c7472bacf9fd')">
					</el-table-column>
					<el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')">
					</el-table-column>
				</el-table>

				<div class="tableCon_pagination">
					<div style="text-align: center;" v-if="loading_load"><i class="el-icon-loading"></i></div>
					<!-- <hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination> -->
					<el-button type="primary" icon="el-icon-check" @click="sureAction()">{{$t('i18nn_760f75dc397a9cb8')}}</el-button>
					<span>{{$t('i18nn_824815ceb3f6c6db')}}</span>
					<strong class="red">{{multipleSelection.length}}</strong>
					<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>
					<!-- ；
					<div v-if="pagination.page_size<pagination.total">
						<span>{{$t('i18nn_1ff0f0c0745be010')}}</span>
						<strong class="red">{{pagination.page_size>=pagination.total?pagination.total:pagination.page_size}}</strong>
						<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>，
						<span>{{$t('73bbf51487ec69e9')}}</span>
						<strong class="red">{{pagination.total}}</strong>
						<span>{{$t('i18nn_117aa3f27e5e430c')}}</span>；
						<span><span>{{$t('i18nn_f7fac56017a0ca84')}}</span><span class="red">{{$t('1e7246dd6ccc5539')}}</span></span>
					</div> -->
				</div>
			</div>
		</div>
		<!-- <div slot="footer" class="dialog-footer">
			<el-button type="primary" plain @click="dialog = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button>
		</div> -->
	</el-drawer>
	<!-- </el-dialog> -->
</template>

<script>
	// import WSkuInventories from '@/components/StorageCenter/WSkuProduct/WSkuInventories.vue';
	// import whNoSelect from '@/components/WarehouseCenter2/components/whNoSelect.vue';
	// import SelAgentUser from '@/components/Common/SelAgentUser.vue';
	import ElTableInfiniteScroll from '@/directive/el-table-infinite-scroll/index.js';
	export default {
		props: {
			openTime: {},
			// userId:"",
			// goodsSku: {
			// 	default: function() {
			// 		return '';
			// 	},
			// 	type: String
			// }
		},
		directives: {
			"el-table-infinite-scroll": ElTableInfiniteScroll,
		},
		components: {
			// whNoSelect,
			// SelAgentUser
		},
		data() {
			return {
				dialog: false,
				// loading_load: false,
				// tableData: [],

				loading_load: false,
				infinite_loading_disabled: false,
				tableData: [],
				multipleSelection: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					// wh_weight_unit: [],
					// wh_vol_unit: [],
					// wh_goods_fee_type: [],
				},
				//查询，排序方式
				filterData: {
					// orderBy: 'id_', //排序字段
					// sortAsc: 'desc', //desc降序，asc升序
					orderBy: 'register_time', //排序字段
					sortAsc: 'N', //desc降序，asc升序
					// "accountPeriod":"",
					// "billNo":""
					// status: '',
					// putWhNo: '',
					// agentUser: '',
					whNo: '',
					// goodsSku: '',
					// hashCode: '',
					// goodsName: '',
					// goodsNameEn: '',
					keyword: ''
					// declareNameCh: '',
				},

			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialog = true;
			}
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				console.log('initData');
				this.pagination.current_page = 1;
				// this.currentSel = {};
				this.multipleSelection = [];
				this.tableData = [];
				this.infinite_loading_disabled = false;
				this.getPageData();
				// this.getDicData();
			},
			// changWhNo(data) {
			// 	console.log('changWhNo', data);
			// 	this.filterData.whNo = data.code;
			// 	// this.filterData.userId = data.userId;
			// 	this.initData();
			// },
			// showDetList($event, row) {
			// 	$event.stopPropagation();
			// 	this.dialogShowDet = true;
			// 	this.DetData = [row.mySku];
			// },
			// changeAgentUser(val) {
			// 	this.filterData.agentUser = val;
			// 	this.initData();
			// },
			sureAction(){
				this.$emit('sure', this.multipleSelection);
				this.dialog = false;
			},
			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				this.multipleSelection = val;
				this.$emit('SelChange', val);
			},

			//分页的筛选项数据
			pageFilterData() {
				return {
					sortAsc: this.filterData.sortAsc,
					orderBy: this.filterData.orderBy,
					// proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					// userId: this.userId,
					// status: this.filterData.status ? this.filterData.status : null,
					// putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
					// whNo: this.filterData.whNo ? this.filterData.whNo : null,
					// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
					// hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
					// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					// goodsNameEn: this.filterData.goodsNameEn ? this.filterData.goodsNameEn : null,
					keyword: this.filterData.keyword ? this.filterData.keyword : null,
					// mobileLike: this.filterData.keyword? this.filterData.keyword : null,
					// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
				};
			},

			//请求分页数据
			getPageData() {
				// if(!this.userId){
				// 	this.$message.warning(this.$t('i18nn_c0c353c17e6f5792'));
				// 	return;
				// }
				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size, //当前页显示数目
						
					},
					this.pageFilterData()
				);
				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.HyBizUserSimplePage, filterData)
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						if(1==this.pagination.current_page){
							this.tableData = data.rows;
						} else {
							this.tableData = this.tableData.concat(data.rows);
						}
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//加载完成，不再加载
						if (this.tableData.length >= this.pagination.total) {
							this.infinite_loading_disabled = true;
						}
						this.$nextTick(()=>{
							if(this.$refs.multipleTable){
								this.$refs.multipleTable.doLayout();
							}
						});
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},
			load() {
				console.log('load',this.infinite_loading_disabled);
				
				if (this.infinite_loading_disabled) return;
				if (this.tableData.length < this.pagination.total) {
					// this.data = this.data.concat(dataTemplate);
					this.pagination.current_page++;
					this.getPageData();
				}
			
				// if (this.tableData.length >= this.pagination.total) {
				// 	this.infinite_loading_disabled = true;
				// }
			},
			//选择数据后回调
			// selRow(event, row) {
			// 	event.stopPropagation();
			// 	this.$emit('selectRow', row);
			// },
			//请求分页数据
			// getListData() {
			// 	this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhMyGoodsSkuPageList, {
			// 			offset: "0",
			// 			limit: "100",
			// 			goodsSku: this.goodsSku ? this.goodsSku : null,
			// 		})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_4195755cd53e871a'));
			// 			console.log(data);
			// 			this.loading_load = false;
			// 			if (200 == data.code) {
			// 				this.tableData = data.rows;
			// 			} else {
			// 				this.$alert(this.$t('tips.queryErrorFailed'), this.$t('tips.tipsTitle'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.loading_load = false;
			// 			this.$alert(this.$t('tips.queryRequestFailed'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },
		}
	};
</script>

<style>
</style>
